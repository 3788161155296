import React from 'react';
import PropTypes from 'prop-types';

const MenuIcon = ({ color }) => (
    <svg width="50px" height="50px" viewBox="0 0 48 48">
        <defs>
            <path
                d="M19 12a1 1 0 010 2H1a1 1 0 010-2h18zm0-6a1 1 0 010 2H1a1 1 0 110-2h18zm0-6a1 1 0 010 2H1a1 1 0 110-2h18z"
                id="s_icons_navigation_menu_svg__a"
            />
        </defs>
        <g
            fill="none"
            fillRule="evenodd"
        >
            <path d="M0 0h48v48H0z" />
            <g transform="translate(14 17)">
                <mask id="s_icons_navigation_menu_svg__b" fill={color}>
                    <use xlinkHref="#s_icons_navigation_menu_svg__a" />
                </mask>
                <use fill={color} fillRule="nonzero" xlinkHref="#s_icons_navigation_menu_svg__a" />
                <g mask="url(#s_icons_navigation_menu_svg__b)" fill={color}>
                    <path d="M-14-17h48v48h-48z" />
                </g>
            </g>
        </g>
    </svg>
);

MenuIcon.propTypes = {
    color: PropTypes.string
};

MenuIcon.defaultProps = {
    color: '#FFF'
};

export default MenuIcon;
